<template>
  <div>
    <el-form :model="form" ref="form" :hide-required-asterisk="true">
      <el-form-item
        :label="'Office'"
        prop="office"
        :rules="[{ required: true, message: 'Office is required' }]"
      >
        <el-select
          class="w-100"
          v-model="form.office"
          placeholder="Select Office"
          @change="getClients"
        >
          <el-option
            v-for="item in offices"
            :key="item.id"
            :label="item.office_name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('Insurance Type')" prop="insuranceType">
            <el-select
              v-model="form.insuranceType"
              placeholder="Insurance Type"
              class="w-100"
            >
              <el-option value="c" label="Commercial">Commercial</el-option>
              <el-option value="p" label="Personal">Personal</el-option>
            </el-select>
          </el-form-item>
      <el-form-item :label="$t('Bill Type')" prop="billType">
            <el-select
              v-model="form.billType"
              placeholder="Bill Type"
              class="w-100"
            >
              <el-option value="direct" label="Direct">Direct</el-option>
              <el-option value="agency" label="Agency">Agency</el-option>
            </el-select>
          </el-form-item>
      <el-form-item
        :label="'Client'"
        prop="corporate"
        :rules="[{ required: true, message: 'The field is required' }]"
      >
        <v-select
          class="style-chooser w-100"
          placeholder="Select Client"
          :options="corporates"
          label="name"
          :reduce="(corporate) => corporate.id"
          v-model="form.corporate"
        ></v-select>
      </el-form-item>
      <el-form-item
        :label="$t('Coverage')"
        prop="coverage"
        :rules="[{ required: true, message: 'The field is required' }]"
      >
        <v-select
          class="style-chooser w-100"
          placeholder="Select Coverage"
          :options="coverages"
          label="name"
          :reduce="(coverage) => coverage.id"
          v-model="form.coverage"
        ></v-select>
      </el-form-item>
      <el-form-item
        :label="$t('Wholesaler')"
        prop="wholesaler"
        :rules="[{ required: true, message: 'The field is required' }]"
      >
        <v-select
          class="style-chooser w-100"
          placeholder="Select Wholesaler"
          :options="wholesalers"
          label="name"
          :reduce="(wholesaler) => wholesaler.id"
          v-model="form.wholesaler"
        ></v-select>
      </el-form-item>
      <el-form-item
        :label="$t('Carrier')"
        prop="carrier"
        :rules="[{ required: true, message: 'The field is required' }]"
      >
        <v-select
          class="style-chooser w-100"
          placeholder="Select Carrier"
          :options="carriers"
          label="name"
          :reduce="(carrier) => carrier.id"
          v-model="form.carrier"
        ></v-select>
      </el-form-item>
      <el-form-item
        :label="$t('Finance Company')"
        prop="financeCompany"
        :rules="[{ required: true, message: 'The field is required' }]"
      >
        <v-select
          class="style-chooser w-100"
          placeholder="Select Finance Company"
          :options="financeCompanies"
          label="name"
          :reduce="(financeCompany) => financeCompany.id"
          v-model="form.financeCompany"
        ></v-select>
      </el-form-item>
      <el-form-item
        :label="$t('Policy #')"
        prop="policy"
      >
        <el-input
          type="text"
          v-model="form.policy"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('Effective Date')"
        prop="effectiveDate"
        :rules="[{ required: true, message: 'The field is required' }]"
      >
        <el-date-picker
          class="w-100"
          v-model="form.effectiveDate"
          type="date"
          value-format="yyyy-MM-dd"
          format="MM/dd/yyyy"
          placeholder="Pick a day"
        ></el-date-picker>
      </el-form-item>
      <el-form-item
        :label="$t('Expiration Date')"
        prop="expirationDate"
        :rules="[{ required: true, message: 'The field is required' }]"
      >
        <el-date-picker
          class="w-100"
          v-model="form.expirationDate"
          type="date"
          value-format="yyyy-MM-dd"
          format="MM/dd/yyyy"
          placeholder="Pick a day"
        ></el-date-picker>
      </el-form-item>
       <el-form-item
        :label="'Total Premium'"
        prop="totalPremium"
      >
        <el-input type="number" v-model="form.totalPremium"></el-input>
      </el-form-item>
       <el-form-item
        :label="'(--) Downpayment'"
        prop="downpayment"
      >
        <el-input type="number" v-model="form.downpayment"></el-input>
      </el-form-item>
      <el-form-item :label="$t('Track Installments')" prop="trackInstallments">
        <el-select
          v-model="form.trackInstallments"
          placeholder="Track Installments"
          class="w-100"
          :disabled="data !== null"
        >
          <el-option :value="1" label="Yes">Yes</el-option>
          <el-option :value="0" label="No">No</el-option>
        </el-select>
      </el-form-item>
      <div v-if="form.trackInstallments">
        <el-form-item
          label="# of installments"
          prop="installments"
        >
          <el-input :disabled="data !== null" type="number" v-model="form.installments" @change="arrayInstallements"></el-input>
        </el-form-item>
        <el-form-item
          label="Date of 1st Installement"
          prop="startDateInstallment"
        >
          <el-date-picker
            class="w-100"
            v-model="form.startDateInstallment"
            @change="arrayInstallements"
            :disabled="data !== null"
            type="date"
            value-format="yyyy-MM-dd"
            format="MM/dd/yyyy"
            placeholder="Pick a day"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="Frequency" prop="frequency">
          <el-select
            v-model="form.frequency"
            placeholder="Installements Frequency"
            class="w-100"
            :disabled="data !== null"
            @change="arrayInstallements"
          >
            <el-option value="monthly" label="Monthly">Monthly</el-option>
            <el-option value="quarterly" label="Quarterly">Quarterly</el-option>
          </el-select>
        </el-form-item>
        <h5>Access to Online Policy</h5>
        <div class="row ml-7" v-if="form.link">
          <div>
            <p class="float-left mr-1">url:</p>
            <a :href="form.link" v-if="form.link != null" target="_blank">{{
              form.link
            }}</a>
          </div>
        </div>
        <el-form-item :label="$t('Link')" prop="link">
          <el-input type="text" v-model="form.link"></el-input>
        </el-form-item>
        <div class="row">
          <div class="col-6">
            <el-form-item :label="$t('license.user')" prop="user">
              <el-input type="text" v-model="form.user"></el-input>
            </el-form-item>
          </div>
          <div class="col-6">
            <el-form-item :label="$t('license.password')" prop="password">
              <el-input type="text" v-model="form.password"></el-input>
            </el-form-item>
          </div>
        </div>
        <table class="table table-bordered">
          <thead>
            <tr>
              <th rowspan="2" scope="col" class="text-center align-middle">#</th>
              <th rowspan="2" scope="col" class="text-center align-middle">Installment Amount</th>
              <th rowspan="2" scope="col" class="text-center align-middle">Other Fees</th>
              <th rowspan="2" scope="col" class="text-center align-middle">Audit</th>
              <th rowspan="2" scope="col" class="text-center align-middle">Carrier Amount</th>
              <th colspan="2" scope="col" class="text-center align-middle">Cabrera Insurance</th>
              <th colspan="3" scope="col" class="text-center align-middle"># Days Before Due Date</th>
              <th rowspan="2" scope="col" class="text-center align-middle">Due Date</th>
            </tr>
            <tr>
              <th scope="col" class="text-center align-middle">Cabrera Fees</th>
              <th scope="col" class="text-center align-middle">Cabrera Collection</th>
              <th scope="col" class="text-center align-middle">
                <el-input :disabled="data !== null" type="number" class="mb-1" v-model="daysEmail" @change="arrayInstallements"></el-input>
                Send Email
              </th>
              <th scope="col" class="text-center align-middle">
                <el-input :disabled="data !== null" type="number" class="mb-1" v-model="daysCollection" @change="arrayInstallements"></el-input>
                Collection Date
              </th>
              <th scope="col" class="text-center align-middle">
                <el-input :disabled="data !== null" type="number" class="mb-1" v-model="daysPayment" @change="arrayInstallements"></el-input>
                Payment Date
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="form.installmentsData.length === 0">
              <td scope="row" colspan="11" class="text-center">Complete Fields</td>
            </tr>
            <tr v-else v-for="(item,i) in form.installmentsData" :key="i">
              <th scope="row">{{ item.number }}/{{form.installments}}</th>  
              <td><el-input type="number" v-model="item.amount" @change="{item.carrier_amount = getCarrierAmount(item);item.c_collection = getCabreraAmount(item)}"></el-input></td> 
              <td><el-input type="number" v-model="item.other_fees" @change="{item.carrier_amount = getCarrierAmount(item);item.c_collection = getCabreraAmount(item)}"></el-input></td> 
              <td><el-input type="number" v-model="item.audit" @change="{item.carrier_amount = getCarrierAmount(item);item.c_collection = getCabreraAmount(item)}"></el-input></td> 
              <td class="bg-info"><el-input type="number" disabled v-model="item.carrier_amount"></el-input></td> 
              <td><el-input type="number" v-model="item.c_fees"  @change="item.c_collection = getCabreraAmount(item)"></el-input></td> 
              <td class="bg-success"><el-input type="number" disabled v-model="item.c_collection" ></el-input></td> 
              <td>
                <el-date-picker
                  class="w-100"
                  v-model="item.email_date"
                  :disabled="item.email_date_processed != null"
                  type="date"
                  value-format="yyyy-MM-dd"
                  format="MM/dd/yyyy"
                  placeholder="Pick a day"
                ></el-date-picker>
              </td> 
              <td>
                <el-date-picker
                  class="w-100"
                  v-model="item.collection_date"
                  :disabled="item.collection_date_processed != null"
                  type="date"
                  value-format="yyyy-MM-dd"
                  format="MM/dd/yyyy"
                  placeholder="Pick a day"
                ></el-date-picker>
              </td> 
              <td>
                <el-date-picker
                  class="w-100"
                  v-model="item.payment_date"
                  :disabled="item.payment_date_processed != null"
                  type="date"
                  value-format="yyyy-MM-dd"
                  format="MM/dd/yyyy"
                  placeholder="Pick a day"
                ></el-date-picker>
              </td> 
              <td>
                <el-date-picker
                  class="w-100"
                  v-model="item.due_date"
                  type="date"
                  value-format="yyyy-MM-dd"
                  format="MM/dd/yyyy"
                  placeholder="Pick a day"
                ></el-date-picker>
              </td> 
            </tr>
            <tr>
              <td scope="row">
                Total ==>>
              </td>
              <td>
                $ {{amountSummary}}
              </td>
              <td>
                $ {{feesSummary}}
              </td>
              <td>
                $ {{auditSummary}}
              </td>
              <td>
                $ {{carrierSummary}}
              </td>
              <td>
                $ {{c_feesSummary}}
              </td>
              <td>
                $ {{c_collectionSummary}}
              </td>
              <td colspan="4"></td>
            </tr>
          </tbody>
        </table>
      </div>
      <el-form-item :label="$t('license.observation')" prop="observation">
        <el-input
          type="textarea"
          v-model="form.observation"
          :rows="5"
        ></el-input>
      </el-form-item>

      <el-form-item
        :label="$t('Status')"
        prop="status"
        :rules="[{ required: true, message: 'Status is required' }]"
      >
        <el-select
          v-model="form.status"
          placeholder="Select Status"
          class="w-100"
        >
          <el-option
            v-for="item in status_select"
            :key="item.id"
            :label="item.name"
            :value="item.status"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-button
        type="primary"
        @click="!data ? submitForm('form') : updateForm('form')"
        >Save Policy</el-button
      >
    </el-form>
  </div>
</template>

<script>
import office from "@/services/api/office";
import officeUser from "@/services/api/officeUser";
import corporate from "@/services/api/corporate";
import coverage from "@/services/api/coverage";
import wholesaler from "@/services/api/wholesaler";
import carrier from "@/services/api/carrier";
import financeCompany from "@/services/api/financeCompany";
import policy from "@/services/api/policy";

export default {
  props: ["data"],
  data() {
    return {
      offices: [],
      corporates: [],
      coverages: [],
      wholesalers: [],
      carriers: [],
      financeCompanies: [],
      daysEmail: 9,
      daysCollection: 4,
      daysPayment: 2,
      status_select: [
        { status: 'Active', name: "Active" },
        { status: 'Expired', name: "Expired" },
        { status: 'Cancelled', name: "Cancelled" },
      ],
      form: {
        id: null,
        office: null,
        insuranceType: null,
        billType: null,
        corporate: null,
        coverage: null,
        wholesaler: null,
        carrier: null,
        financeCompany: null,
        policy: null,
        effectiveDate: null,
        expirationDate: null,
        totalPremium: null,
        downpayment: null,
        trackInstallments: 0,
        installments: null,
        startDateInstallment: null,
        frequency: null,
        link: null,
        user: null,
        password: null,
        observation: null,
        installmentsData: [],
        status: 'Active',
      },
    };
  },
  mounted() {
    coverage.get().then((response) => {
      this.coverages = response;
    });
    wholesaler.get().then((response) => {
      this.wholesalers = response;
    });
    carrier.get().then((response) => {
      this.carriers = response;
    });
    financeCompany.get().then((response) => {
      this.financeCompanies = response;
    });
    switch (this.$store.getters.discriminator) {
      case "administrator":
        office.get().then((response) => {
          this.offices = response;
        });
        break;
      case "office":
      case "employee":
        officeUser.getOffice(this.$store.getters.id).then((response) => {
          this.offices = response.map((item) => {
            return {
              id: item.office.id,
              office_name: item.office.office_name,
            };
          });
        });
        break;
    }
    if (this.data) {
      this.form.id = this.data.id;
      this.form.office = this.data.officeId;
      this.getClients()
      this.form.insuranceType = this.data.insurance_type;
      this.form.billType = this.data.bill_type;
      this.form.corporate = this.data.corporate.id;
      this.form.coverage = this.data.coverage.id;
      this.form.wholesaler = this.data.wholesaler.id;
      this.form.carrier = this.data.carrier.id;
      this.form.financeCompany = this.data.finance_company.id;
      this.form.policy = this.data.policy;
      this.form.effectiveDate = this.data.effective_date;
      this.form.expirationDate = this.data.expiration_date;
      this.form.totalPremium = this.data.total_premium;
      this.form.downpayment = this.data.downpayment;
      this.form.trackInstallments = this.data.track_installments;
      this.form.installments = this.data.installments;
      this.form.startDateInstallment = this.data.start_date_installment
      this.form.frequency = this.data.frequency
      this.form.link = this.data.link
      this.form.user = this.data.user
      this.form.password = this.data.password
      this.form.installmentsData = this.data.installments_data;
      this.form.observation = this.data.observation
      this.form.status = this.data.status
    }
  },
  methods: {
    getCarrierAmount(item) {
      let carrierAmount = 0
      if (item.amount) carrierAmount += parseFloat(item.amount)
      if (item.other_fees) carrierAmount += parseFloat(item.other_fees)
      if (item.audit) carrierAmount += parseFloat(item.audit)
      return carrierAmount.toFixed(2)
    },
    getCabreraAmount(item) {
      let cabreraAmount = 0
      if (item.c_fees) cabreraAmount += parseFloat(item.c_fees)
      if (item.carrier_amount) cabreraAmount += parseFloat(item.carrier_amount)
      return cabreraAmount.toFixed(2)
    },
    getClients() {
      this.form.corporate = null;
      corporate.getClientsByOffice(this.form.office).then((response) => {
        this.corporates = response;
      });
    },
    createInstallemets() {
      this.form.installmentsData = []
      for (var i = 0; i < this.form.installments; i++) {
        this.form.installmentsData.push({
          number: i+1,
          amount: '',
          other_fees: '',
          audit: '',
          carrier_amount: '',
          c_fees: '',
          c_collection: '',
          email_date: '',
          collection_date: '',
          payment_date: '',
          due_date: '',
        })
      }
      this.arrayInstallements()
    },
    arrayInstallements() {
      function formatDate(currentDate) { 
        var date = currentDate.getDate();
        var month = currentDate.getMonth(); 
        var year = currentDate.getFullYear();
        var stringDate = year + "-" + (month+1) + "-" + date;
        return stringDate
      }
      if(this.form.trackInstallments && this.form.installments && this.form.startDateInstallment && this.form.frequency) {
        if( this.form.installmentsData.length === 0) {
          this.createInstallemets()
        } else {
          const daysEmail = this.daysEmail
          const daysCollection = this.daysCollection
          const daysPayment = this.daysPayment
          // eslint-disable-next-line no-useless-escape
          const dateInstallment = new Date(this.form.startDateInstallment.replace(/-/g, '\/'))
          const frecuency = this.form.frequency
          this.form.installmentsData.forEach(function(item, index) {
            this[index].due_date = formatDate(dateInstallment)
            var copiedDate = new Date(dateInstallment.getTime());
            copiedDate.setDate(copiedDate.getDate() - daysPayment)
            this[index].payment_date =  formatDate(copiedDate)
            copiedDate.setDate(copiedDate.getDate() - (daysCollection - daysPayment))
            this[index].collection_date =  formatDate(copiedDate)
            copiedDate.setDate(copiedDate.getDate() - (daysEmail - daysCollection))
            this[index].email_date =  formatDate(copiedDate)

            if(frecuency === 'monthly') {
              dateInstallment.setMonth(dateInstallment.getMonth() + 1);
            } else if(frecuency=== 'quarterly') {
              dateInstallment.setMonth(dateInstallment.getMonth() + 3);
            }
            
          },this.form.installmentsData)

        }
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          policy
            .create(this.form)
            .then((response) => {
              this.$emit("refresh", { data: response, update: false });
            })
            .catch(() => {
              this.$message({
                message: this.$store.getters.message.message,
                type: "error",
                customClass: "message-error",
              });
            });
        } else {
          return false;
        }
      });
    },
    updateForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          policy
            .update(this.form)
            .then((response) => {
              this.$emit("refresh", { data: response, update: true });
            })
            .catch(() => {
              this.$message({
                message: this.$store.getters.message.message,
                type: "error",
                customClass: "message-error",
              });
            });
        } else {
          return false;
        }
      });
    },
  },
  computed: {
    amountSummary() {
      let summary = 0
      this.form.installmentsData.forEach(item => {
        if(item.amount) {
          summary += parseFloat(item.amount)
        }
      })
      return summary.toFixed(2)
    },
     feesSummary() {
      let summary = 0
      this.form.installmentsData.forEach(item => {
        if(item.other_fees) {
          summary += parseFloat(item.other_fees)
        }
      })
      return summary.toFixed(2)
    },
     auditSummary() {
      let summary = 0
      this.form.installmentsData.forEach(item => {
        if(item.audit) {
          summary += parseFloat(item.audit)
        }
      })
      return summary.toFixed(2)
    },
     carrierSummary() {
      let summary = 0
      this.form.installmentsData.forEach(item => {
        if(item.carrier_amount) {
          summary += parseFloat(item.carrier_amount)
        }
      })
      return summary.toFixed(2)
    },
     c_feesSummary() {
      let summary = 0
      this.form.installmentsData.forEach(item => {
        if(item.c_fees) {
          summary += parseFloat(item.c_fees)
        }
      })
      return summary.toFixed(2)
    },
     c_collectionSummary() {
      let summary = 0
      this.form.installmentsData.forEach(item => {
        if(item.c_collection) {
          summary += parseFloat(item.c_collection)
        }
      })
      return summary.toFixed(2)
    },
  }
};
</script>

<style lang="scss"></style>

<style lang="scss">
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle {
  height: 45px;
}
.style-chooser .vs__dropdown-menu {
  max-height: 150px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

</style>

